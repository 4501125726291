<template>
  <v-app dark>
    <!-- -------------------------------------- MODES -->
    <v-card class="modes" :disabled="!alphabet">
      <!-- mode btns -->
      <v-btn
        v-for="(data, name) in modes"
        :key="name"
        :color="data?.use ? 'primary' : ''"
        text
        :disabled="!data"
        v-html="_tr(name)"
        @click="
          data.use = !data.use
          stop()
        "
      />
      <!-- play btn -->
      <v-progress-circular
        indeterminate
        color="primary"
        class="circular"
        size="55"
        v-if="is_playing"
      />
      <v-btn
        class="play"
        fab
        small
        :disabled="cannot_play"
        :color="is_playing ? 'primary' : ''"
        @click="trigger()"
        ><v-icon>mdi-{{ is_playing ? "stop" : "play" }}</v-icon></v-btn
      >
    </v-card>

    <!-- -------------------------------------- LIGHT -->

    <v-icon class="light" :color="lights[current_light]['color_' + light_state]"
      >mdi-{{ lights[current_light][light_state] }}</v-icon
    >
    <span class="title_space" id="title_space"> </span>

    <!-- -------------------------------------- SETTINGS -->
    <v-btn
      class="settingsbtn"
      icon
      color="#555"
      @click="toggle_settings = !toggle_settings"
      ><v-icon>mdi-cog</v-icon></v-btn
    >
    <v-dialog v-model="toggle_settings">
      <v-card light @click="save_settings()" @keypress="save_settings()">
        <v-card-title>Settings</v-card-title>
        <v-card-text>
          <!-- -------------------- EXAM ANSWERS -->
          <v-divider />
          <v-card-subtitle>
            <v-checkbox v-model="display_answers" label="Display answers" />
          </v-card-subtitle>
          <!-- -------------------- ALPHABET -->
          <v-divider />
          <v-card-subtitle
            >Alphabet
            <v-btn
              v-for="(preset, index) in alphabet_presets"
              :key="preset"
              small
              elevation="0"
              color="primary"
              class="ml-2 mb-1"
              @click="set_al(index)"
              >{{ preset }}</v-btn
            >
            <br />
            <v-btn
              v-for="(data, letter) in alphabet"
              icon
              :color="data.use ? 'primary' : ''"
              :key="letter"
              @click="data.use = !data.use"
              >{{ letter }}</v-btn
            >
          </v-card-subtitle>
          <!-- -------------------- LIGHTS -->
          <v-divider />
          <v-card-subtitle
            >Light
            <v-btn
              v-for="(data, light) in lights"
              icon
              :color="current_light == light ? 'primary' : ''"
              :key="light"
              @click="current_light = light"
              ><v-icon>mdi-{{ data.off }}</v-icon></v-btn
            >
          </v-card-subtitle>
          <!-- -------------------- SPEED -->
          <v-divider />
          <v-card-subtitle>
            <v-text-field v-model.number="speed" label="Speed" />
          </v-card-subtitle>
          <!-- -------------------- OK -->
          <v-card-title>
            <v-spacer /><v-btn
              @click="toggle_settings = false"
              color="primary"
              elevation="0"
              >OK</v-btn
            >
          </v-card-title>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- -------------------------------------- ANSWERS -->
    <v-dialog v-model="show_answers">
      <v-card light>
        <v-card-title>Answers</v-card-title>
        <v-card-text>
          <span v-for="(answers, mode) in current_exam" :key="mode">
            <v-card-subtitle v-html="_tr(mode)" />
            <ul class="ml-10">
              <li
                v-for="(answer, index) in answers"
                :key="index"
                v-html="answer"
              />
            </ul>
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { alphabet } from "./plugins/text_data"

export default {
  name: "App",
  components: {},

  data: () => ({
    alphabet: null,
    // --------------------------------------------- SETTINGS DATA
    toggle_settings: false,
    alphabet_presets: ["letters", "numbers", "punctuation"],
    to_save: ["current_light", "speed", "alphabet", "display_answers"],
    display_answers: true,
    // --------------------------------------------- LIGHT DATA
    lights: {
      bulb: {
        on: "lightbulb",
        off: "lightbulb-variant-outline",
        color_on: "#fff",
        color_off: "#222",
      },
      circle: {
        on: "circle",
        off: "circle-outline",
        color_on: "#fff",
        color_off: "#222",
      },
    },
    current_light: "bulb",
    light_state: "off",
    // --------------------------------------------- MODE DATA
    modes: {
      // -------------------- STATE
      state: {
        use: true,
        generator: (alphabet, picker) => {
          const line_count = 5
          const letter_count = 5
          return [
            "=",
            ...Array(line_count)
              .fill(0)
              .map(
                () =>
                  Array(letter_count)
                    .fill(0)
                    .map(() => picker(alphabet))
                    .join("") + " II"
              ),
            "=",
          ]
        },
      },
    },
    // ------------------------------ PLAY STATE
    is_playing: false,
    // --------------------------------------------- PLAY SYSTEM
    long_time: 1 * 1000,
    short_time: 0.3 * 1000,
    inter_time: 0.4 * 1000,
    letter_end_time: 1.2 * 1000,
    stop_time: 2 * 1000,
    speed: 1,
    // --------------------------------------------- EXAM DATA
    show_answers: false,
    current_exam: null,
  }),
  computed: {
    cannot_play() {
      return !Object.entries(this.modes).filter(([, data]) => data?.use).length
    },
  },
  methods: {
    // --------------------------------------------- TITLE SYSTEM
    async throw_title(title) {
      const elm = document.createElement("div")
      elm.classList.add("title")
      elm.innerHTML = this._tr(title)
      document.getElementById("title_space").appendChild(elm)
      return new Promise((ok) => {
        setTimeout(() => {
          elm.remove()
          ok()
        }, 1300)
      })
    },
    async count_down_title(title) {
      await this.throw_title(title)
      await this.throw_title("3")
      await this.throw_title("2")
      await this.throw_title("1")
    },
    // --------------------------------------------- PLAY SYSTEM
    async play_time(time) {
      this.$set(this, "light_state", "on")
      return new Promise((ok) =>
        setTimeout(() => {
          this.$set(this, "light_state", "off")
          setTimeout(() => ok(), this.inter_time / this.speed)
        }, time)
      )
    },
    play_short() {
      return this.play_time(this.short_time / this.speed)
    },
    play_long() {
      return this.play_time(this.long_time / this.speed)
    },
    async play_letter(letter) {
      const code = alphabet[letter].code
      console.log("playing letter", letter, code)
      for (const elm of Array.from(code)) {
        if (!this.is_playing) return
        const play_func = elm == "p" ? this.play_short : this.play_long
        await play_func()
      }
      return new Promise((ok) =>
        setTimeout(() => ok(), this.letter_end_time / this.speed)
      )
    },
    async play_word(word) {
      for (const letter of Array.from(word)) {
        if (!this.is_playing) return
        await this.play_letter(letter)
      }
      return new Promise((ok) =>
        setTimeout(() => ok(), this.stop_time / this.speed)
      )
    },
    async play_sentence(sentence) {
      const words = sentence.split(" ")
      for (const word of words) {
        if (!this.is_playing) return
        await this.play_word(word)
      }
    },
    // --------------------------------------------- PLAY TRIGGER
    trigger() {
      const act = this.is_playing ? this.stop : this.play
      act()
    },
    async play() {
      this.is_playing = true

      // ---- generation package
      function picker(array) {
        return array[Math.floor(Math.random() * array.length)]
      }
      const used_alphabet = Object.entries(this.alphabet)
        .filter(([, { use }]) => use)
        .map(([letter]) => letter)

      // ---- exam
      const used_modes = Object.entries(this.modes)
        .filter(([, data]) => data?.use)
        .map(([mode]) => mode)

      const exam = Object.fromEntries(
        used_modes.map((mode) => [
          mode,
          this.modes[mode].generator(used_alphabet, picker),
        ])
      )
      console.log("exam", exam)

      // ---- sentence
      for (const mode in exam) {
        const sentence = exam[mode].join("")
        await this.count_down_title(mode)
        await this.play_sentence(sentence)
      }

      this.stop()

      // ---- show exam responses
      if (this.display_answers) {
        this.$set(this, "show_answers", true)
        this.current_exam = exam
      }
    },
    stop() {
      this.is_playing = false
    },
    // --------------------------------------------- TRANSLATION
    _tr(str) {
      const current_translation = "fr"
      const translations = {
        fr: {
          state: "état",
          clear: "clair",
          code: "codé",
        },
      }
      return translations[current_translation]?.[str] ?? str
    },
    // --------------------------------------------- SETTINGS
    set_al(setting_index) {
      const alphabet_settings = [
        Array.from("ABCDEFGHIJKLMNOPQRSTUVWXYZ"),
        Array.from("0123456789"),
        Array.from(".,:/-=!&?"),
      ]
      const preset = alphabet_settings[setting_index]
      for (const letter in this.alphabet) {
        const data = this.alphabet[letter]
        if (preset.includes(letter)) this.$set(data, "use", !data.use)
      }
    },
    save_settings() {
      const save_object = Object.fromEntries(
        this.to_save.map((key) => [key, this[key]])
      )
      const str_object = JSON.stringify(save_object)
      localStorage.setItem("gscott_settings", str_object)
    },
    load_settings() {
      const str_object = localStorage.getItem("gscott_settings")
      const load_object = JSON.parse(str_object)
      for (const key in load_object) {
        if (key == "modes") {
          for (const mode in load_object[key]) {
            this.$set(this.modes, mode, {
              ...this.modes,
              ...load_object[key][mode],
            })
          }
        } else {
          this.$set(this, key, load_object[key])
        }
      }
    },
  },
  // --------------------------------------------- INIT ALPHABET
  mounted() {
    this.$set(this, "alphabet", alphabet)
    this.load_settings()
  },
}
</script>

<style>
.v-application {
  overflow: hidden;
}
/* ------------------------------------ MODE PANEL */
.modes {
  position: fixed;
  padding: 10px;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
}
.modes .v-btn {
  margin: 5px;
  text-transform: capitalize;
  font-size: inherit;
}
.modes .circular {
  position: absolute;
  left: calc(50% + 5px);
  bottom: -37.4px;
  transform: translateX(-50%);
}
.modes .play.v-btn {
  position: absolute;
  left: 50%;
  bottom: -35px;
  transform: translateX(-50%);
}
/* ------------------------------------ LIGHT */
.light {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 400px !important;
  pointer-events: none;
}
/* ------------------------------------ SETTINGS BTN */
.settingsbtn {
  position: fixed;
  left: 15px;
  bottom: 15px;
}
/* ------------------------------------ TITLE */
.title_space {
  position: fixed;
  width: 1000px;
  height: 100%;
  pointer-events: none;
}
.title_space .title {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px !important;
  margin: 0px;
  padding: 0px;
  width: 100%;
  text-align: center;
  display: inline;
  animation: throw 1.3s 0s ease-in forwards;
  transform-origin: left top;
}
@keyframes throw {
  0% {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
  100% {
    transform: scale(0.5) translate(-50%, -50%);
    opacity: 0;
  }
}
</style>
