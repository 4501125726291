export const alphabet = {
    A: {
        use: true,
        code: 'pt'
    },
    B: {
        use: true,
        code: 'tppp'
    },
    C: {
        use: true,
        code: 'tptp'
    },
    D: {
        use: true,
        code: 'tpp'
    },
    E: {
        use: true,
        code: 'p'
    },
    F: {
        use: true,
        code: 'pptp'
    },
    G: {
        use: true,
        code: 'ttp'
    },
    H: {
        use: true,
        code: 'pppp'
    },
    I: {
        use: true,
        code: 'pp'
    },
    J: {
        use: true,
        code: 'pttt'
    },
    K: {
        use: true,
        code: 'tpt'
    },
    L: {
        use: true,
        code: 'ptpp'
    },
    M: {
        use: true,
        code: 'tt'
    },
    N: {
        use: true,
        code: 'tp'
    },
    O: {
        use: true,
        code: 'ttt'
    },
    P: {
        use: true,
        code: 'pttp'
    },
    Q: {
        use: true,
        code: 'ttpt'
    },
    R: {
        use: true,
        code: 'ptp'
    },
    S: {
        use: true,
        code: 'ppp'
    },
    T: {
        use: true,
        code: 't'
    },
    U: {
        use: true,
        code: 'ppt'
    },
    V: {
        use: true,
        code: 'pppt'
    },
    W: {
        use: true,
        code: 'ptt'
    },
    X: {
        use: true,
        code: 'tppt'
    },
    Y: {
        use: true,
        code: 'tptt'
    },
    Z: {
        use: true,
        code: 'ttpp'
    },
    0: {
        use: true,
        code: 'ttttt'
    },
    1: {
        use: true,
        code: 'ptttt'
    },
    2: {
        use: true,
        code: 'ppttt'
    },
    3: {
        use: true,
        code: 'ppptt'
    },
    4: {
        use: true,
        code: 'ppppt'
    },
    5: {
        use: true,
        code: 'ppppp'
    },
    6: {
        use: true,
        code: 'tpppp'
    },
    7: {
        use: true,
        code: 'ttppp'
    },
    8: {
        use: true,
        code: 'tttpp'
    },
    9: {
        use: true,
        code: 'ttttp'
    },
    '.': {
        use: true,
        code: 'ptptpt'
    },
    ',': {
        use: true,
        code: 'ttpptt'
    },
    ':': {
        use: true,
        code: 'tttppp'
    },
    '/': {
        use: true,
        code: 'tpptp'
    },
    '-': {
        use: true,
        code: 'tpppt'
    },
    '=': {
        use: true,
        code: 'tpppt'
    },
    '!': {
        use: true,
        code: 'ptptptt'
    },
    '&': {
        use: true,
        code: 'ptppp'
    },
    '?': {
        use: true,
        code: 'ppttpp'
    }
}